import gql from 'graphql-tag';

export const userFragment = gql`
  fragment UserFragment on User {
    id
    name
    given_name
    family_name
    fraud_check
    email
    nickname
    vytal_qr_code
    container_transaction_count
    role
    phone_number
    active_campaigns {
      id
      quantity_max
      promotion_code
      start_date
      end_date
      terms
      discount_percent
      user_quantity
      offer_name
      referral_name
    }
    has_payment
    payment_details {
      paypal_id
      stripe_id
      default_source {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
      sources {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
    }
  }
`;
export const userPaymentFragment = gql`
  fragment UserPaymentFragment on User {
    id
    name
    vytal_qr_code
    completed_privacy_onboarding
    payment_details {
      paypal_id
      stripe_id
      default_source {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
      sources {
        id
        brand
        last4
        object
        fingerprint
        name
        type
      }
    }
  }
`;
