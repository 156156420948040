import gql from 'graphql-tag';
import * as fragments from './fragments';

export const getUserQuery = gql`
  query ($forceRefresh: Boolean, $sessionDeviceID: ID) {
    getUser(forceRefresh: $forceRefresh, session_device_id: $sessionDeviceID) {
      ...UserFragment
    }
  }
  ${fragments.userFragment}
`;

export const getUserWithPaymentQuery = gql`
  query ($forceRefresh: Boolean, $sessionDeviceID: ID) {
    getUser(forceRefresh: $forceRefresh, session_device_id: $sessionDeviceID) {
      ...UserPaymentFragment
    }
  }
  ${fragments.userPaymentFragment}
`;

export const getPaymentClientTokenQuery = gql`
  query {
    paymentClientToken {
      braintreeToken
    }
  }
`;

export const getUserHasPaymentQuery = gql`
  query ($forceRefresh: Boolean, $sessionDeviceID: ID) {
    getUser(forceRefresh: $forceRefresh, session_device_id: $sessionDeviceID) {
      id
      vytal_qr_code
      email
      has_payment
    }
  }
`;

export const getAppLoginTokenQuery = gql`
  query ($app_login_token: ID!) {
    appLoginToken(id: $app_login_token) {
      id
      user_id
    }
  }
`;

export const checkEmailQuery = gql`
  query checkEmail($email: String!) {
    checkEmail(email: $email)
  }
`;

export const getExternalAuthClientConfigQuery = gql`
  query getExternalAuthClientConfig($hostname: String!, $client_id: String) {
    getExternalAuthClientConfig(hostname: $hostname, client_id: $client_id) {
      client_id
      display_name
    }
  }
`;

export const updateUserMutation = gql`
  mutation ($user_id: ID!, $payload: UpdateUser!) {
    updateUser(id: $user_id, item: $payload) {
      id
      vytal_qr_code
    }
  }
`;
