import Router from 'next/router';
import { ParsedUrlQuery } from 'querystring';

export const routerPushWrapper = async (href: string): Promise<boolean> =>
  Router.push(getWrappedLink(href));

/**
 * Add a URL parameter (or changing it if it already exists)
 * Does not work with anchor links
 */
export function addUrlParam(search: string, key: string, val: string) {
  if (search.includes('#')) {
    throw new Error('addUrlParam: anchor links are not supported');
  }
  const includesOtherParams = search.includes('?');
  return `${search + (includesOtherParams ? '&' : '?') + key}=${encodeURIComponent(val)}`;
}

export function getWrappedLink(href: string, routerQuery?: ParsedUrlQuery) {
  // return original link, if routerQuery is not available
  if (typeof window === 'undefined' && !routerQuery) {
    return href;
  }

  const {
    sdk_callback_url: sdkCallbackUrl,
    client_id: clientId,
    request_cookie_consent: requestCookieConsent,
    cookie_consent_given: cookieConsentGiven,
  } = routerQuery || Router.query;
  let adjustedHref = href;
  if (sdkCallbackUrl) {
    adjustedHref = addUrlParam(
      adjustedHref,
      'sdk_callback_url',
      sdkCallbackUrl as string,
    );
  }
  if (clientId) {
    adjustedHref = addUrlParam(adjustedHref, 'client_id', clientId as string);
  }
  if (requestCookieConsent) {
    adjustedHref = addUrlParam(
      adjustedHref,
      'request_cookie_consent',
      requestCookieConsent as string,
    );
  }
  if (cookieConsentGiven) {
    adjustedHref = addUrlParam(
      adjustedHref,
      'cookie_consent_given',
      cookieConsentGiven as string,
    );
  }
  return adjustedHref;
}
