const stage = process.env.NEXT_PUBLIC_STAGE;

const VYTAL_APP_LOGIN_TOKEN_TTL_SECONDS = 60 * 60; // 1 hour in seconds, has to match colugo

const sharedConfig = {
  VYTAL_WEB_CLIENT_ID: 'eac_vytal_web',
  VYTAL_APP_LOGIN_TOKEN_TTL_SECONDS,
  GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '',
};

const prodConfig = {
  VYTAL_CAPI: 'https://consumerapi.vytal.org/api',
  APOLLO_ENDPOINT: 'https://colugo.vytal.org',
  APOLLO_WEBSOCKET_ENDPOINT: 'wss://ws.pickpack.de/prod',
  STRIPE_PUBLIC_KEY:
    'pk_live_51GzKl9LPQ6sF4MhE419TPTzXpBNwLObKXCw1m925T9CQ85EtptfjpE4QNbQTnZSeg4MMb3g3AtJjzfuhbuqrzmVG003M2yaIsD',
  VYTAL_APP_LOGIN_DEEP_LINK: 'https://vytal.app.link/xxhrG9Tn7rb',
  VYTAL_APP_DOWNLOAD_LINK: 'https://vytal.app.link/vytal-app-web-login', // redirects to App or Play Store
  ...sharedConfig,
};

const devConfig = {
  VYTAL_CAPI: 'https://consumerapi-dev.vytal.org/api',
  APOLLO_ENDPOINT: 'https://colugo-dev.vytal.org',
  APOLLO_WEBSOCKET_ENDPOINT: 'wss://ws.pickpack.de/dev',
  STRIPE_PUBLIC_KEY:
    'pk_test_51GzKl9LPQ6sF4MhElssoWtebJdlb4JzTEKKMFbPgGKl96qguyRt6TUeUi2VwObTRGRYkHtga6e5BxP7fyLlPEdpf00ulPckIo2',
  // VYTAL_APP_LOGIN_DEEP_LINK: 'https://vytal.test-app.link/Z68H9nZd8sb', // dev deep link
  VYTAL_APP_LOGIN_DEEP_LINK: 'https://vytal.app.link/xxhrG9Tn7rb',
  VYTAL_APP_DOWNLOAD_LINK: 'https://vytal.app.link/vytal-app-web-login', // redirects to App or Play Store
  ...sharedConfig,
};

export const getEnv = (): 'dev' | 'prod' => (stage === 'prod' ? 'prod' : 'dev');

// @ts-ignore
export const AppConfig = getEnv() === 'prod' ? prodConfig : devConfig;
