import { SDKMessageType } from './webSdkCommunicatorTypes';
import Router from 'next/router';
import { createApolloClient } from './apolloClient';
import { getExternalAuthClientConfigQuery } from '../graphql/queries';
import { ApolloQueryResult } from '@apollo/client';

let targetWindow: any = null;

if (typeof window !== 'undefined') {
  targetWindow = window.opener || window.parent;
  console.log({
    targetWindow,
    isSameWindow: targetWindow === window,
    opener: window.opener,
    parent: window.parent,
  });
}

const whitelistedHostnameCache: any = {};

const prepareMessageTransfer = async (): Promise<URL> => {
  const { sdk_callback_url: sdkCallbackUrl, client_id: clientId } =
    Router.query;
  if (!sdkCallbackUrl) {
    console.error('sdk_callback_url not available');
    throw new Error('sdk_callback_url not available');
  }

  const callbackUrl = new URL(sdkCallbackUrl as string);
  const hostname = callbackUrl.hostname;

  // check cache before fetching network
  if (whitelistedHostnameCache[hostname]) {
    return callbackUrl;
  }

  let externalAuthClientRes: ApolloQueryResult<any> | null = null;
  try {
    const apolloClient = createApolloClient({
      jwt: null,
      refreshToken: null,
      clientId: null,
    });
    externalAuthClientRes = await apolloClient.query({
      query: getExternalAuthClientConfigQuery,
      variables: {
        client_id: clientId || '',
        hostname: hostname,
      },
    });
    whitelistedHostnameCache[hostname] =
      externalAuthClientRes.data.getExternalAuthClientConfig;
  } catch (e: any) {
    if (
      !e.graphQLErrors ||
      e.graphQLErrors.length !== 1 ||
      e.graphQLErrors[0] !== 'EXTERNAL_AUTH_CLIENT_INVALID'
    ) {
      console.error(
        'prepareMessageTransfer unexpected error getting auth client',
        { e, callbackUrl },
      );
      alert('Unexpected error validating client. Please reload the page.');
    }
  }

  if (
    !externalAuthClientRes ||
    (externalAuthClientRes.errors && externalAuthClientRes.errors.length >= 0)
  ) {
    console.log('prepareMessageTransfer invalid hostname', {
      hostname: callbackUrl.hostname,
      clientId,
      errors:
        externalAuthClientRes &&
        externalAuthClientRes.errors &&
        JSON.stringify(externalAuthClientRes.errors),
    });
    alert(`invalid hostname for web-login-sdk use: ${callbackUrl.hostname}`);
    throw new Error(`invalid hostname: ${callbackUrl.hostname}`);
  }

  return callbackUrl;
};

export const sendNotLoggedInMessage = async () => {
  const parentUri = await prepareMessageTransfer();

  const messagePayload = {
    type: SDKMessageType.NOT_LOGGED_IN,
  };

  console.log('sendNotLoggedInMessage', { messagePayload });

  targetWindow.postMessage(JSON.stringify(messagePayload), parentUri.origin);
};

export const sendUserLoggedOutMessage = async () => {
  const parentUri = await prepareMessageTransfer();
  const messagePayload = {
    type: SDKMessageType.LOGOUT_SUCCESS,
  };

  targetWindow.postMessage(JSON.stringify(messagePayload), parentUri.origin);
};

export const sendUserIsLoggedInMessage = async (
  vytalQrCode: string,
  userId: string,
  encryptedJwt?: string,
  encryptedRefreshToken?: string,
  loginActionByUser = true,
) => {
  const parentUri = await prepareMessageTransfer();

  const messagePayload = {
    type: loginActionByUser
      ? SDKMessageType.LOGIN_SUCCESS_BY_USER
      : SDKMessageType.LOGIN_SUCCESS_AUTO,
    vytalQrCode,
    userId,
    encryptedJwt,
    encryptedRefreshToken,
  };
  // console.log('sendUserIsLoggedInMessage', { messagePayload });

  targetWindow.postMessage(JSON.stringify(messagePayload), parentUri.origin);
};

export const sendCloseIframeMessage = async () => {
  const parentUri = await prepareMessageTransfer();

  const messagePayload = {
    type: SDKMessageType.CLOSE_MODAL,
  };

  targetWindow.postMessage(JSON.stringify(messagePayload), parentUri.origin);
};
