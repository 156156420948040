import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { useMemo } from 'react';
import { setContext } from '@apollo/client/link/context';
import { AppConfig } from '../utils/config';
import { AuthContext } from './authMiddleware';
import { SentryLink } from 'apollo-link-sentry';
import { Vytal } from './vytal';
// Please note: the

declare global {
  // eslint-disable-next-line no-var
  var _jwt_overwrite: string;
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const hasApolloJwt = () => !!window._jwt_overwrite;

export const apolloUpdateJwt = (jwt: string | undefined) => {
  // @ts-ignore
  window._jwt_overwrite = jwt;
};

const getAuthLink = (authContext: AuthContext) => {
  const { jwt: jwtIn, refreshToken: refreshTokenIn } = authContext;
  return setContext(async (_, { headers }) => {
    try {
      const jwt =
        (typeof window !== 'undefined' && window._jwt_overwrite) || jwtIn;
      const authHeader = jwt ? `Bearer ${jwt}` : 'ANONYMOUS';
      console.log('getAuthLink setContext', authHeader);
      return {
        headers: {
          ...headers,
          authorization: authHeader,
          'client-name': 'vytal-web',
          // 'Accept-Language': getGlobalContext().pickpackLocale || 'de',
        },
      };
    } catch (err: any) {
      throw new Error(err);
    }
  });
};

const httpLink = new HttpLink({ uri: AppConfig.APOLLO_ENDPOINT });

export function createApolloClient(authContext: AuthContext) {
  const authLink = getAuthLink(authContext);

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // in the future we could merge with colugo and link directly to the schema to save
    // an extra HTTP Request. Here is an example:
    // https://github.com/leighhalliday/apollo-nextjs/blob/master/src/apollo.ts
    link: ApolloLink.from([
      authLink,
      new SentryLink({
        uri: AppConfig.APOLLO_ENDPOINT,
      }),
      httpLink,
    ]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(
  authContext: AuthContext,
  initialState: any = null,
) {
  const _apolloClient = apolloClient ?? createApolloClient(authContext);

  if (initialState) {
    // @ts-ignore
    _apolloClient.cache.restore(initialState);
  }

  if (typeof window === 'undefined') return _apolloClient;
  apolloClient = apolloClient ?? _apolloClient;

  return apolloClient;
}

export function useApollo(authContext: AuthContext, initialState: any) {
  const store = useMemo(
    () => initializeApollo(authContext, initialState),
    [authContext.jwt, initialState],
  );
  return store;
}
